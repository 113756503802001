import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import {
    Box,
    createIcon,
    Flex,
    Heading,
    Text,
    HStack,
    StackProps,
    HTMLChakraProps,
    useColorModeValue as mode,
} from '@chakra-ui/react'

import { CheckIcon } from '@chakra-ui/icons'


export const RightArrow = createIcon({
    viewBox: '0 0 11 12',
    d: 'M0 0L4.8 6L0 12H5.78182L10.5818 6L5.78182 0H0Z',
})

const FeatureLink = (props: HTMLChakraProps<'a'>) => {
    const { children, ...rest } = props as StackProps
    return (
        <HStack as="a" align="center" fontSize="md" className="group" cursor="pointer" {...rest}>
            <CheckIcon w={4} h={4} color="green.500" />
            <Box fontWeight="semibold">{children}</Box>
            {/* <RightArrow
                color={mode('blue.500', 'blue.400')}
                fontSize="sm"
                transition="transform 0.2s"
                pos="relative"
                top="2px"
                _groupHover={{ transform: 'translateX(2px)' }}
            /> */}
        </HStack>
    )
}

const NosotrosSection = () => {
    return (
        <>

            <Box as="section" py={{ base: '0', lg: '24' }}>
                <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
                    <Flex direction={{ base: 'column-reverse', lg: 'row' }} justify="space-between">
                        <Box flex="1" maxW={{ lg: 'xl' }} py={{ base: '12', lg: '0' }}>

                            <StaticImage style={{ width: '100%', height: '100%', objectFit: 'cover' }} src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2550&q=80" alt="Doctores celebrando" />
                            {/* <Img
                                htmlWidth="560px"
                                htmlHeight="430px"
                                w="100%"
                                h="100%"
                                objectFit="cover"
                                src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2550&q=80"
                            /> */}
                        </Box>
                        <Box ms={{ lg: '12' }} mt={{ base: '12', lg: 0 }} flex="1" maxW={{ lg: 'xl' }}>
                            <Box maxW={{ base: 'xl', lg: 'unset' }}>
                                <Text px="1" fontWeight="extrabold" letterSpacing="tight" fontSize="xl" color={mode('blue.500', 'blue.300')}>Filosofía</Text>
                                {/* <Box as="b" color={mode('blue.500', 'blue.300')}>
                                    Marketing Dental Digital
                                </Box> */}
                                <Heading mt="0" mb="5" size="2xl" fontWeight="extrabold" lineHeight="1.2">
                                    Misión
                                </Heading>
                                <Text textAlign={'justify'} fontSize="lg" color={mode('gray.600', 'gray.400')}>
                                    Ofrecer sistemas de captación de pacientes para clínicas dentales en medios digitales a través del marketing dental y modernas tecnologías web para potenciar el flujo de pacientes y primeras visitas ahorrando tiempo y dinero.
                                </Text>
                                <Heading mt="6" mb="5" size="2xl" fontWeight="extrabold" lineHeight="1.2">
                                    Visión
                                </Heading>
                                <Text textAlign={'justify'} fontSize="lg" color={mode('gray.600', 'gray.400')}>
                                    Dicen que la vida nos sonríe cuando pasan grandes cosas. Estamos aquí para ayudar a impactar positivamente
                                    las vidas de muchas personas que anhelan volver a sonreír superando complejos e inseguridades a través de
                                    un excelente tratamiento dental.
                                    <Text />
                                    <Text textAlign={'justify'} mt={4} fontSize="lg" color={mode('gray.600', 'gray.400')}></Text>
                                    Al ayudar a las personas a encontrar la doctora o el doctor adecuado para
                                    ser su guía en esa transición, también queremos impactar positivamente al negocio dental y a los doctores
                                    quienes podrán contar con los pacientes que le permitan practicar el tipo de odontología avanzada que inspire su
                                    progreso profesional.
                                </Text>
                            </Box>
                        </Box>
                    </Flex>
                </Box>
            </Box>
        </>
    )
}

const links = [
    { label: 'Estrategia Dental Digital', href: '#' },
    { label: 'SEO Dental', href: '#' },
    { label: 'Publicidad Dental Online', href: '#' },
    { label: 'Gestión de Redes Sociales', href: '#' },
    { label: 'Email Marketing Dental', href: '#' },
    { label: 'Marketing de Contenido Dental', href: '#' },
]

export default NosotrosSection