import React from "react";
import {
    Box, Heading, Text, useColorModeValue as mode,
} from '@chakra-ui/react'

import { StaticImage } from "gatsby-plugin-image"

export const NosotrosHero = () => {
    return (
        <Box
            as="section"
            /* bg={mode('gray.50', 'gray.800')} */
            pb={{ base: '0', lg: '24' }}
            pos="relative"
            px={{ base: '6', lg: '12' }}

        >
            <Box maxW="7xl" mx="auto">
                <Box
                    maxW={{ lg: 'md', xl: 'xl' }}
                    pt={{ base: '20', lg: '40' }}
                    pb={{ base: '16', lg: '24' }}
                >


                    <Text px="1" fontWeight="extrabold" letterSpacing="tight" fontSize="xl">EMPODERAMOS AL SECTOR DENTAL</Text>


                    <Heading as="h1" size="3xl" lineHeight="1" fontWeight="extrabold" letterSpacing="tight">
                        <Text
                            display={{ base: "block", lg: "inline" }}
                            w="full"
                            bgClip="text"
                            bgGradient="linear(to-r, green.400,purple.500)"
                            fontWeight="extrabold"

                        >
                            Danios Digital
                        </Text>


                        {/* <Box as="mark" color={mode('blue.500', 'blue.300')} bg="transparent">
              más sonrisas.
            </Box> */}
                    </Heading>
                    <Text mt={8} fontSize="xl" fontWeight="medium" color={mode('gray.600', 'gray.400')}>
                        {/* Somos una agencia de marketing dental en medios digitales con el firme propósito 
                        de multiplicar sonrisas al conectar el proveedor adecuado de servicio dental con 
                        cada persona que lo necesita ayudando a conseguir la sonrisa de sus sueños. */}


                        {/* Somos una agencia de marketing dental en medios digitales con el firme propósito
                        de multiplicar sonrisas al conectar a cada persona que necesita o desea transformar su sonrisa
                        con el proveedor de servicio dental adecuado. */}

                        Somos una agencia de marketing dental en medios digitales con el firme propósito
                        de multiplicar sonrisas al conectar a las personas con el proveedor de servicio
                        dental adecuado que transformará su sonrisa en la de sus sueños.

                    </Text>
                </Box>
            </Box>
            <Box
                pos={{ lg: 'absolute' }}
                insetY={{ lg: '0' }}
                insetEnd={{ lg: '0' }}
                bg="gray.50"
                w={{ base: 'full', lg: '50%' }}
                height={{ base: '96', lg: 'full' }}
                sx={{
                    clipPath: { lg: 'polygon(8% 0%, 100% 0%, 100% 100%, 0% 100%)' },
                }}
            >
                {/* <HeroImg /> */}
                <StaticImage style={{ width: '100%', height: '100%', objectFit: 'cover' }} src="../../images/thumbup2.png" alt="Doctor" />



                {/* <Img
                    height="100%"
                    width="100%"
                    objectFit="cover"
                    src="https://images.unsplash.com/photo-1551836022-b06985bceb24?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80"
                    src={`thumbup2.png`}
                    alt="Lady working"
                /> */}
            </Box>
        </Box>
    )
}
export default NosotrosHero