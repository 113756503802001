import * as React from "react"

import Layout from "../components/layouts/layout"
import Seo from "../components/utils/seo"
import NosotrosHero from "../components/sections/nosotrosHero"
import NosotrosSection from "../components/sections/nosotrosSection"
import Cta from "../components/sections/cta"

const Nosotros = () => (
    <Layout>
        <Seo title="Nosotros" />
        <NosotrosHero />
        <NosotrosSection />
        <Cta />
    </Layout>
)

export default Nosotros